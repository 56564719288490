export interface Organization {
  children: OrganizationEntityLink[];
  city: string;
  dateLastModified: Date;
  email: string;
  fax: string;
  identifier: string;
  metadata: { key: string; value: string }[];
  name: string;
  parent: OrganizationEntityLink;
  phone: string;
  provider: string;
  readOnly: boolean;
  sisId: string;
  sisName: string;
  sourcedId: string;
  state: string;
  status: OrganizationStatus;
  streetAddress: string;
  timezone: string;
  type: OrganizationType;
  updatedDate?: number;
  updatedUser?: string;
  zip: string;
}

export interface OrganizationEntityLink {
  sourcedId: string;
  href: string;
  type: OrganizationType;
}

// incomplete
export enum OrganizationType {
  CUSTOMER = 'customer',
  SCHOOL = 'school',
  SOURCE = 'source',
}

// incomplete
export enum OrganizationStatus {
  ACTIVE = 'active',
}

export interface Classroom {
  classCode: string;
  classType: string;
  course: unknown;
  dateLastModified: Date;
  grades: string[];
  location: string;
  metadata: { key: string; value: string }[];
  periods: unknown[];
  provider: string;
  readOnly: boolean;
  school: OrganizationEntityLink;
  sisId: string;
  sisName: string;
  sourceId: string;
  sourcedId: string;
  status: string;
  subjectCodes: unknown[];
  subjects: unknown[];
  terms: unknown[];
  title: string;
  updatedDate?: string;
  updatedUser?: string;
}

export enum AcademicSessionType {
  SCHOOL_YEAR = 'schoolYear',
}

export interface AcademicSession {
  children: OrganizationEntityLink[];
  dateLastModified: Date;
  endDate: string;
  metadata: string;
  parent: OrganizationEntityLink;
  schoolYear: string;
  sourcedId: string;
  startDate: string;
  status: OrganizationStatus;
  title: string;
  type: AcademicSessionType;
  sDate: Date;
  eDate: Date;
}

export interface AcademicSessionCreateInput {
  type: AcademicSessionType;
  title: string;
  schoolYear: string;
  startDate: string;
  endDate: string;
}

export interface OrganizationCreateInput {
  type: string;
  name: string;
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  fax: string;
  email: string;
  timezone: string;
}

export interface ClassroomCreateInput {
  title: string;
  grades: string[];
  classCode?: string;
  classType?: string;
  location?: string;
}

export interface RosteringGrade {
  key: string;
  value: string;
}

export interface CoursesDistrictConfiguration {
  erp: boolean;
  ems: boolean;
  smartstart: boolean;
  curriculet: boolean;
  wacs: boolean;
}
